<script lang="ts">
	import { displayLanguages, type LANGUAGE } from '$lib/constants/locale';
	import type { SeoMetadataType } from '$types/seo.types';
	export let data: SeoMetadataType;
	import { getContext } from 'svelte';
	import { page } from '$app/stores';
	import { PUBLIC_SITEMAP_URL } from '$env/static/public';
	import { LOCALIZELINK } from '$utils/link';

	const lang = getContext<LANGUAGE>('lang');
	export let link: string;

	const DEFAULT_METADATA = {
		title: '89transfers: Mallorca Transfers & Palma Airport Transfers',
		description:
			'Expert Mallorca & Palma airport transfers. 100% local service. Map-based pickups, smart scheduling. Island-wide coverage. Book in 2 minutes.',
		keywords: '',
		robotsIndex: 'index, follow',
		og: {
			type: 'website',
			image: 'url',
			siteName: '89transfers',
			imageAlt: '89transfers'
		},
		contentLanguage: 'en',
		author: '89transfers',
		revisitAfter: '20 days'
	};
</script>

<svelte:head>
	<title>
		{data.title ?? DEFAULT_METADATA.title}
	</title>
	<link rel="alternate" hreflang="en" href={PUBLIC_SITEMAP_URL + LOCALIZELINK('en', link)} />
	<link rel="alternate" hreflang="es" href={PUBLIC_SITEMAP_URL + LOCALIZELINK('es', link)} />
	<link rel="alternate" hreflang="de" href={PUBLIC_SITEMAP_URL + LOCALIZELINK('de', link)} />
	<link rel="alternate" hreflang="x-default" href={PUBLIC_SITEMAP_URL + LOCALIZELINK('en', link)} />
	<link rel="canonical" href={PUBLIC_SITEMAP_URL + LOCALIZELINK(lang, link)} />
	<meta name="title" content={data.title ?? DEFAULT_METADATA.title} />
	<meta name="description" content={data.description ?? DEFAULT_METADATA.description} />
	<meta name="keywords" content={data.keywords} />
	<meta name="robots" content={data.robotsIndex} />
	<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
	<meta name="revisit-after" content="20 days" />
	<meta http-equiv="content-language" content="en" />
	<meta name="author" content="89transfers" />
	<meta property="og:type" content="website" />
	<meta property="og:title" content={data.title} />
	<meta property="og:url" content={$page.url.href} />
	<meta property="og:image" content={data.og.image} />
	<meta property="og:description" content={data.description ?? DEFAULT_METADATA.description} />
	<meta property="og:site_name" content="89transfers" />
	<meta property="og:image:width" content="1200" />
	<meta property="og:image:height" content="630" />
	<meta property="og:image:type" content="image/png" />
	<meta property="og:image:alt" content="89transfers" />
</svelte:head>
